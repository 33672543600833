import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import font from './fonts/Sarabun-Regular.ttf'
import THBText from 'thai-baht-text'

Font.register({ family: 'Sarabun', src: font })

// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      fontFamily: 'Sarabun',
      marginTop: 20
    },
    title: {
      fontWeight: 'ultrabold',
      textAlign: 'center',
      fontSize: 16
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'col'
    },
    signSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      paddingTop: 100,
      paddingLeft: 200
    },
    text: {
      margin: 10,
      fontSize: 12,
      lineHeight: 1.7,
      fontFamily: 'Sarabun'
    },
    autograph: {
      margin: 10,
      fontSize: 12,
      lineHeight: 1.7,
      alignItems: 'flex-end',
      fontFamily: 'Sarabun'
    },
    firstParagraph: {
      margin: 10,
      fontSize: 12,
      fontFamily: 'Sarabun'
    }
  });

// Create Document Component
export const MyDocument = ({
  contract,
  propertyInfo,
  tenant,
  landlord
}) => {
  const displayDepositAmount = contract.depositAmount ? THBText(parseInt(contract.depositAmount)) : ''
  const displayRentalRates = contract.rentalRates ? THBText(parseInt(contract.rentalRates)) : ''
  const RenewalConditionInfo = () => {
    if (contract.renewalRentIncreaseType === 'same') {
      return (
        <Text style={styles.text}>
          ข้อ 3. ผู้เช่าตกลงชำระค่าเช่าให้แก่ผู้ให้เช่าทุกๆ วันที่ {contract.paymentDate} ของเดือนเริ่มตั้งแต่เดือนที่ตกลงทำสัญญาเช่าฉบับนี้
          เป็นต้นไป โดยผู้เช่าตกลงเช่ามีกำหนด {contract.rentalPeriod} ปี หากครบกำหนดดังกล่าวแล้ว ผู้เช่ามีสิทธิจะเช่าต่อไปในอัตรา
          ค่าเช่าเดิมก็ได้โดยแจ้งล่วงหน้าให้ผู้ให้เช่าทราบไม่น้อยกว่า {contract.noticeDays} วัน
        </Text>
      )
    }
    return (
      <Text style={styles.text}>
        ข้อ 3. ผู้เช่าตกลงชำระค่าเช่าให้แก่ผู้ให้เช่าทุกๆ วันที่ {contract.paymentDate} ของเดือนเริ่มตั้งแต่เดือนที่ตกลงทำสัญญาเช่าฉบับนี้
        เป็นต้นไป โดยผู้เช่าตกลงเช่ามีกำหนด {contract.rentalPeriod} ปี หากครบกำหนดดังกล่าวแล้ว ผู้เช่ามีสิทธิจะเช่าต่อไปโดยยินยอมให้ผู้ให้เช่า
        ขึ้นค่าเช่าได้ไม่เกิน {contract.renewalRentIncreasePercentage} เปอร์เซ็น โดยผู้เช่าแจ้งล่วงหน้าให้ผู้ให้เช่าทราบไม่น้อยกว่า {contract.noticeDays} วัน
      </Text>
    )
  }
  return (
  <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.section}>
          <Text style={styles.title}>หนังสือสัญญาเช่าห้องชุด</Text>
          <Text style={styles.text}>
          หนังสือสัญญาเช่าฉบับนี้ ทำที่ {contract.contractSigningLocation} เมื่อวันที่ {contract.contractDate}  ทำขึ้นระหว่าง.
          </Text>
          <Text style={styles.text}>
          {landlord.firstName} {landlord.lastName}  บ้านเลขที่ {landlord.address.houseNumber}  ซอย{landlord.address.soi} แขวง/ตำบล{landlord.address.subDistrict} เขต/อำเภอ{landlord.address.district}  จังหวัด{landlord.address.province}  รหัสไปรษณีย์ {landlord.address.postal}  บัตรประจำตัวประชาชนเลขที่ {landlord.id} ซึ่งต่อไปในสัญญาฉบับนี้เรียกว่า “ผู้เช่า” ฝ่ายหนึ่ง
          </Text>
          <Text style={styles.text}>
            กับ {tenant.firstName} {tenant.lastName} บ้านเลขที่ {tenant.address.houseNumber}  ซอย{tenant.address.soi} แขวง/ตำบล{tenant.address.subDistrict}  เขต/อำเภอ{tenant.address.district}  จังหวัด{tenant.address.province}  รหัสไปรษณีย์ {tenant.address.postal}  บัตรประจำตัวประชาชนเลขที่ {tenant.id}
            ซึ่งต่อไปในสัญญาฉบับนี้เรียนกว่า “ผู้ให้เช่าอีกฝ่ายหนึ่ง”
          </Text>
          <Text style={styles.text}>
            คู่สัญญาทั้งสองฝ่ายตกลงทำสัญญากันโดยมีเงื่อนไขและรายละเอียดดังต่อไปนี้.
          </Text>
          <Text style={styles.text}>
            ข้อ 1. ผู้เช่าตกลงเช่าและผู้ให้เช่าตกลงให้เช่าห้อง เลขที่ {propertyInfo.houseNumber} ห้อง {propertyInfo.roomNumber} ซอย{propertyInfo.soi} ถนน{propertyInfo.street}  แขวง/ตำบล{propertyInfo.subDistrict} เขต{propertyInfo.district} จังหวัด{propertyInfo.province}  รหัสไปรษณีย์ {propertyInfo.postal} ในวันที่ทำสัญญาฉบับนี้เป็นต้นไป ในราคาค่าเช่าเดือนละ {contract.rentalRates} บาท ({displayRentalRates})
          </Text>
          <Text style={styles.text}>
          ข้อ 2. ผู้เช่าตกลงจ่ายค่าเช่าล่วงหน้าในวันทำสัญญา เป็นเงินจำนวน {contract.depositAmount} บาท ({displayDepositAmount})
          </Text>
          <RenewalConditionInfo/>
          <Text style={styles.text} wrap={false}>
            ข้อ 4. ผู้ให้เช่าตกลงให้ผู้เช่าใช้สอยทรัพย์สินทุกชนิดที่อยู่ในห้องเช่าและตามรายการทรัพย์สินที่แนบท้าย สัญญานี้โดย ผู้เช่าจะดูแลรักษาเสมือนหนึ่งว่าเป็นทรัพย์สินของตน หากชำรุดบกพร่องใดๆ ผู้เช่าจะต้องซ่อมแซมให้คงเดิมอยู่เสมออ
          </Text>
          <Text style={styles.text}>
            ข้อ 5. ผู้เช่าตกลงที่จะดูแลรักษาห้องที่เช่าให้คงสภาพดีดังเดิมทุกประการและยินยอมให้ผู้ให้เช่าหรือผู้ที่ได้รับ
            มอบหมายเข้ามาในห้องที่เช่าได้ตลอดเวลาเพื่อตรวจดูสภาพห้องที่เช่าได้ทุกเวลาโดยผู้เช่าให้สัญญาว่าจะไม่นำ
            สิ่งผิดกฎหมายเข้ามาในห้องที่เช่า หากผู้ให้เช่าพบหรือบุคคลอื่นพบสิ่งผิดกฎหมายผู้เช่ายอมให้ผู้ให้เช่า บอกเลิกสัญญาเช่าได้ทันที.
          </Text>
          <Text style={styles.text}>
            ข้อ 6. การเช่าตามข้อ 1 ให้รวมถึงอุปกรณ์ หรือทรัพย์สินต่างๆ ที่อยู่ในห้องเช่าและรายการทรัพย์สินตาม
          รายการแนบท้ายหนังสือสัญญาเช่านี้
          </Text>
          <Text style={styles.text}>
            ข้อ 7. ผู้เช่าตกลงที่จะเช่าเพื่อเป็นที่พักอาศัยเท่านั้น และให้สัญญาว่าจะไม่นำห้องที่เช่าออกให้ผู้อื่นเช่าช่วง
            เว้นแต่จะได้รับความยินยอมเป็นหนังสือจากผู้ให้เช่า.
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.section}>
          <Text style={styles.text}>
            ข้อ 8. หากผู้เช่าผิดสัญญาข้อหนึ่งข้อใด ยอมให้ผู้ให้เช่าบอกเลิกสัญญาเช่าได้ทันที และยอมชดใช้ค่าเสียหาย
            ค่าขาดประโยชน์ตามความเหมาะสมและตามสมควร เท่าที่ผู้ให้เช่าจะเสียหาย
          </Text>
          <Text style={styles.text}>
            ข้อ 9. หากสัญญาเช่าสิ้นสุดลง โดยไม่มีการต่อสัญญาเช่า หรือผู้ให้เช่าบอกเลิกสัญญาเช่าตามข้อ 8 ดังกล่าว
            ผู้เช่ายอมขนย้ายทรัพย์สินและบริวารออกไปจากห้องที่เช่าทันทีโดยค่าใช้จ่ายของผู้เช่าเอง
          </Text>
          <Text style={styles.text}>
            ข้อ 10. ผู้เช่าสัญญาว่าจะปฏิบัติตามระเบียบข้อบังคับของอาคารโดยถือเป็นส่วนหนึ่งแห่งสัญญาเช่านี้ด้วย
          หากผู้เช่าไม่ปฏิบัติตาม หรือละเมิดข้อบังคับดังกล่าว ผู้เช่ายินดีให้ถือว่าสัญญาเช่านี้เป็นอันยกเลิกต่อกัน
          และยินยอมมอบการครอบครองห้องเช่าคืนแก่ผู้ให้เช่าทันที คู่สัญญาทั้งสองฝ่ายได้อ่านข้อความดังกล่าวแล้ว
          ตกลงที่จะทำสัญญาฉบับนี้ จึงลงลายมือชื่อไว้เป็นสำคัญ..
          </Text>
          <View style={styles.signSection}>
            <Text style={styles.autograph}>
            ลงชื่อ........................................ผู้เช่า
    ({tenant.firstName} {tenant.lastName})</Text>
            <Text style={styles.autograph}>
                    ลงชื่อ........................................ผู้ให้เช่า
            ({landlord.firstName} {landlord.lastName})</Text>
            <Text style={styles.autograph}>
                    ลงชื่อ........................................พยาน
            (........................................)</Text>
            <Text style={styles.autograph}>
                    ลงชื่อ........................................พยาน
            (........................................)</Text>
          </View>
        </View>
      </Page>
  </Document>
  )
}
