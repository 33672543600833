import * as React from 'react'
import { useSelector } from 'react-redux'
import { PDFViewer } from '@react-pdf/renderer'
import { MyDocument } from './pdf'

const Preview = () => {
  const contractFromState = useSelector(state => state.contract)
  const propertyInfoFromState = useSelector(state => state.propertyInfo)
  const tenantFromState = useSelector(state => state.tenant)
  const landlordFromState = useSelector(state => state.landlord)

  const mockContract = {
    paymentDate: '18',
    rentalRates: '15,000',
    displayRentalRates: 'หนึ่งหมื่นห้าพันบาทถ้วน',
    rentalPeriod: '3',
    contractSigningLocation: 'กรุงเทพ',
    renewalRentIncreaseType: 'same',
    renewalRentIncreasePercentage: 10,
    noticeDays: '30',
    penaltyRates: '15',
    contractDate: '18 ธันวาคม 2564',
    depositAmount: '170,000',
    displayDepositAmount: 'หนึ่งแสนเจ็ดหมื่นบาทถ้วน'
  }
  const mockPropertyInfo = {
    houseNumber: '111/111',
    soi: 'พญาไท 5',
    street: 'พญาไท',
    subDistrict: 'แจ้งนะจ้ะ',
    district: 'บางกระท่อน',
    province: 'กรุงเทพ',
    postal: '10330'
  }
  const mockTenant = {
    firstName: 'สมชาย',
    lastName: 'นอนหงาย',
    id: '',
    address: mockPropertyInfo
  }
  const mockLandlord = {
    firstName: 'จอนห์',
    lastName: 'วิค',
    id: '',
    address: mockPropertyInfo
  }
  const isPDFdevelopmentMode = false
  const contract = isPDFdevelopmentMode ? mockContract : contractFromState
  const propertyInfo = isPDFdevelopmentMode ? mockPropertyInfo : propertyInfoFromState
  const tenant = isPDFdevelopmentMode ? mockTenant : tenantFromState
  const landlord = isPDFdevelopmentMode ? mockLandlord : landlordFromState
  return (
    <PDFViewer id="preview">
      <MyDocument
        contract={contract}
        propertyInfo={propertyInfo}
        tenant={tenant}
        landlord={landlord}
        />
    </PDFViewer>
  )
}

export default Preview
