import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import {
  contractActions
} from './store'

const ContractCondition = (props) => {
  const contract = useSelector(state => state.contract)
  const dispatch = useDispatch()
  return (
    <div>
      <Card>
        <Card.Header>รายละเอียดค่าเช่าทั่วไป</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
                วันจ่ายค่าเช่า (วันของเดือน)
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text"
                  value={contract.paymentDate}
                  onChange={(value) => dispatch(contractActions.updateField('paymentDate', value.target.value))}
                />
              </Col>
              <Col sm={4}>
                จ่ายทุกๆ วันที่ xx ของเดือน
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
                ค่าเช่า (บาท/เดือน)
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text"
                  value={contract.rentalRates}
                  onChange={(value) => dispatch(contractActions.updateField('rentalRates', value.target.value))}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
               ระยะเวลาสัญญา
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text"
                  value={contract.rentalPeriod}
                  onChange={(value) => dispatch(contractActions.updateField('rentalPeriod', value.target.value))}
                />
              </Col>
              <Col sm={4}>
                เดือน
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
               สถานที่ทำสัญญาเช่าฉบับนี้
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text"
                  value={contract.contractSigningLocation}
                  onChange={(value) => dispatch(contractActions.updateField('contractSigningLocation', value.target.value))}
                />
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>รายละเอียดการต่อสัญญา เมื่อสัญญาครบระยะที่กำหนด</Card.Header>
        <Card.Body>
        <Form>
          <Form.Check
            label="สามารถต่อสัญญาในราคาเช่าเดิม"
            checked={contract.renewalRentIncreaseType === 'same'}
            onChange={(value) => dispatch(contractActions.updateField('renewalRentIncreaseType', value.target.value))}
            value="same"
            name="group1"
            type="radio"
            id="inline-radio-1"
          />
          <Form.Check
            label="สามารถต่อสัญญาโดยมีอัตราขึ้นค่าเช่าได้ไม่เกิน"
            checked={contract.renewalRentIncreaseType === 'increase'}
            onChange={(value) => dispatch(contractActions.updateField('renewalRentIncreaseType', value.target.value))}
            value="increase"
            name="group1"
            type="radio"
            id="inline-radio-2"
          />
          {contract.renewalRentIncreaseType === 'increase' &&
            <Form.Group as={Row} className="mb-3" >
              <Col sm={1}></Col>
              <Form.Label column sm={5}>
                อัตราขึ้นค่าเช่าได้ไม่เกิน (เปอร์เซ็น)
              </Form.Label>
              <Col sm={3}>
                <Form.Control type="text"
                  value={contract.renewalRentIncreasePercentage}
                  onChange={(value) => dispatch(contractActions.updateField('renewalRentIncreasePercentage', value.target.value))}
                />
              </Col>
            </Form.Group>
          }
          <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm={6}>
              จำนวนวันที่ต้องแจ้งล่วงหน้าเพื่อต่อสัญญาภายใน (วัน)
            </Form.Label>
            <Col sm={3}>
              <Form.Control type="text"
                value={contract.noticeDays}
                onChange={(value) => dispatch(contractActions.updateField('noticeDays', value.target.value))}
              />
            </Col>
          </Form.Group>
        </Form>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>รายละเอียดค่าปรับ</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
                ปรับวันละ (บาท)
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text"
                  value={contract.penaltyRates}
                  onChange={(value) => dispatch(contractActions.updateField('penaltyRates', value.target.value))}
                />
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <div className="d-grid gap-2 button-group">
        <Link
          className="btn btn-danger btn-md"
          role="button"
          to="/deposit"
        >
          ถัดไป
        </Link>
        <Link
          className="btn btn-secondary btn-md"
          role="button"
          to="/tenant"
        >
          ย้อนกลับ
        </Link>
      </div>
    </div>
  )
}

export default ContractCondition
