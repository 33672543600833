import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { useDispatch } from 'react-redux'

const PersonalInfoForm = (props) => {
  const dispatch = useDispatch()
  return (
    <Form>
      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm={2}>
          ชื่อ
        </Form.Label>
        <Col sm={4}>
          <Form.Control type="text"
            value={props.person?.firstName}
            onChange={(value) => dispatch(props.actions?.updateFirstName(value.target.value))}
          />
        </Col>
        <Form.Label column sm={2}>
          นามสกุล
        </Form.Label>
        <Col sm={4}>
          <Form.Control type="text"
            value={props.person?.lastName}
            onChange={(value) => dispatch(props.actions?.updateLastName(value.target.value))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm={4}>
        รหัสประจำตัวประชาชน
        </Form.Label>
        <Col sm={6}>
          <Form.Control type="text"
            value={props.person?.id}
            onChange={(value) => dispatch(props.actions?.updateId(value.target.value))}
          />
        </Col>
      </Form.Group>
    </Form>
  )
}

export default PersonalInfoForm
