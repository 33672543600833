import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import { useDispatch } from 'react-redux'


const AddressForm = (props) => {
  const dispatch = useDispatch()
  return (
    <Form>
      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm={2}>
          บ้านเลขที่
        </Form.Label>
        <Col sm={4}>
          <Form.Control type="text"
            value={props.address?.houseNumber}
            onChange={(value) => dispatch(props.actions?.updateAddressField('houseNumber',value.target.value))}
          />
        </Col>
        <Form.Label column sm={2}>
          ซอย
        </Form.Label>
        <Col sm={4}>
          <Form.Control type="text"
            value={props.address?.soi}
            onChange={(value) => dispatch(props.actions?.updateAddressField('soi',value.target.value))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm={2}>
        ถนน
        </Form.Label>
        <Col sm={6}>
          <Form.Control type="text"
            value={props.address?.street}
            onChange={(value) => dispatch(props.actions?.updateAddressField('street',value.target.value))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm={2}>
        ตำบล/แขวง
        </Form.Label>
        <Col sm={6}>
          <Form.Control type="text"
            value={props.address?.subDistrict}
            onChange={(value) => dispatch(props.actions?.updateAddressField('subDistrict',value.target.value))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm={2}>
        อำเภอ/เขต
        </Form.Label>
        <Col sm={6}>
          <Form.Control type="text"
            value={props.address?.district}
            onChange={(value) => dispatch(props.actions?.updateAddressField('district',value.target.value))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" >
        <Form.Label column sm={2}>
        จังหวัด
        </Form.Label>
        <Col sm={4}>
          <Form.Control type="text"
            value={props.address?.province}
            onChange={(value) => dispatch(props.actions?.updateAddressField('province',value.target.value))}
          />
        </Col>
        <Form.Label column sm={2}>
        รหัสไปรษณีย์
        </Form.Label>
        <Col sm={4}>
          <Form.Control type="text"
            value={props.address?.postal}
            onChange={(value) => dispatch(props.actions?.updateAddressField('postal',value.target.value))}
          />
        </Col>
      </Form.Group>
    </Form>
  )
}


export default AddressForm
