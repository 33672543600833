import {configureStore, createSlice} from '@reduxjs/toolkit';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorageUtils';


const addressInitialState = {
    roomNumber: '',
    houseNumber: '',
    soi: '',
    street: '',
    subDistrict: '',
    district: '',
    province: '',
    postal: ''
}
const personInitialState = {
    firstName: '',
    lastName: '',
    id: '',
    address: addressInitialState
}
const contractInitialState = {
    paymentDate: '',
    rentalRates: '',
    displayRentalRates: '',
    rentalPeriod: '',
    contractSigningLocation: '',
    renewalRentIncreaseType: 'same',
    renewalRentIncreasePercentage: 10,
    noticeDays: '',
    penaltyRates: '',
    contractDate: '',
    depositAmount: '',
    displayDepositAmount: ''
}

// Note1
// multiple params in payload need to use prepare callback?
// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#immutable-updates-with-immer
// Note2
// Don't know how to deduplicate with address reducer
// Could try provide state getter function?
const addressReducers = {
    updateAddressField: {
        reducer(state, action) {
            const {field, value} = action.payload
            state[field] = value
        },
        prepare(field, value) {
            return {
                payload: {field, value}
            }
        }
    }
}
const personReducers = {
    updateFirstName(state, action) {
        state.firstName = action.payload
    },
    updateLastName(state, action) {
        state.lastName = action.payload
    },
    updateId(state, action) {
        state.id = action.payload
    },
    updateAddressField: {
        reducer(state, action) {
            const {field, value} = action.payload
            state.address[field] = value
        },
        prepare(field, value) {
            return {
                payload: {field, value}
            }
        }
    }
}

const propertyInfoState = createSlice({
    name: 'propertyInfo',
    initialState: addressInitialState,
    reducers: addressReducers
})

const landlordSlice = createSlice({
    name: 'landlord',
    initialState: personInitialState,
    reducers: personReducers
})

const tenantSlice = createSlice({
    name: 'tenant',
    initialState: personInitialState,
    reducers: personReducers
})

const contractSlice = createSlice({
    name: 'contract',
    initialState: contractInitialState,
    reducers: {
        updateField: {
            reducer(state, action) {
                const {field, value} = action.payload
                state[field] = value
            },
            prepare(field, value) {
                return {
                    payload: {field, value}
                }
            }
        }
    }
})

export const propertyInfoActions = propertyInfoState.actions
export const landlordActions = landlordSlice.actions
export const tenantActions = tenantSlice.actions
export const contractActions = contractSlice.actions

const store = configureStore({
    reducer: {
        propertyInfo: propertyInfoState.reducer,
        landlord: landlordSlice.reducer,
        tenant: tenantSlice.reducer,
        contract: contractSlice.reducer
    },
    preloadedState: loadFromLocalStorage()
});

// Reference: https://dev.to/link2twenty/react-redux-and-localstorage-2lih
store.subscribe(() => saveToLocalStorage(store.getState()));

export default store
