import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Row from 'react-bootstrap/Row'
// import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { BsFillAlarmFill, BsDownload, BsFillShieldFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import toolsImage from './rental-contract-generator.png'

const TopCardGroup = () => {
  return (
    <Container>
      <Row>
        <CardGroup>
          <Card className="card-home">
            <BsFillAlarmFill size="60" />
            <Card.Body>
              <Card.Title>สร้างสัญญาเช่าง่ายๆ ภายใน 5 นาที</Card.Title>
              <Card.Text>
                เพียงกรอกข้อมูลในระบบ ฟรี! ไม่มีค่าใช้จ่าย
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="card-home">
            <BsDownload size="60" />
            <Card.Body>
              <Card.Title>ดาวน์โหลดสัญญา</Card.Title>
              <Card.Text>
                ดาวน์โหลดสัญญาในรูปแบบ Pdf, Word (เร็วๆนี้){' '}
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="card-home">
            <BsFillShieldFill size="60" />
            <Card.Body>
              <Card.Title>เสร็จเรียบร้อย !</Card.Title>
              <Card.Text>
                  ปลอดภัย ไม่มีการเก็บข้อมูล ไม่มีค่าใช้จ่าย
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </Row>
    </Container>
  )
}

const Heading = () => {
  return (
    <div className="heading-section">
      <img src={toolsImage} alt="logo" className="logo-wrapper"/>
      <div className="heading-text">
        <h1>สร้างสัญญาเช่าง่ายๆ ฟรี !</h1>
        <p>
          Sanyachao ทำการสร้างสัญญาอัตโนมัติโดยการกรอกข้อมูลลงในระบบแล้วดาวน์โหลดไฟล์ pdf ได้ทันที
        </p>
        <p>
          ดาวน์โหลดรูปแบบสัญญาหลากหลายแบบ ทำให้การสร้างสัญญาของคุณง่ายขึ้น และมีประสิทธิภาพมากขึ้น
        </p>
        <p>
          <Link
            className="btn btn-danger btn-md"
            role="button"
            to="/property"
          >
            คลิกเพื่อเริ่มสร้างสัญญา
          </Link>
        </p>
      </div>
    </div>
  )
}

const TemplateList = () => {
  const contractTemplates = [
    {
      name: 'สัญญาเช่าบ้าน',
      downloadUrl: 'https://bit.ly/3iwk8cd',
      downloadUrlPdf: 'https://bit.ly/3DeHs5Z'
    },
    {
      name: 'สัญญาเช่าคอนโด',
      downloadUrl: 'https://bit.ly/3isaK9x',
      downloadUrlPdf: 'https://bit.ly/3lawJ6y'
    },
    {
      name: 'สัญญาเช่าตึกอาคารพาณิชย์',
      downloadUrl: 'https://bit.ly/3iz4VXP',
      downloadUrlPdf: 'https://bit.ly/3uGYF5d'
    },
    {
      name: 'สัญญาเช่าที่ดิน',
      downloadUrl: 'https://bit.ly/3iw3wRZ',
      downloadUrlPdf: 'https://bit.ly/3DcTahy'
    }
  ]

  return (
    <div className="template">
      <div className="template-content">
        <Container>
          <Row>
            <h3>ดาวน์โหลดตัวอย่างรูปแบบสัญญา</h3>
            <hr/>
            { contractTemplates.map((template, index) => (<Col md="6" sm="12" key={`card-${index}`}>
              <Card className="template-card">
                <Card.Body>
                  <Card.Title>{template.name}</Card.Title>
                  <a
                    className="btn btn-danger btn-md"
                    role="button"
                    href={template.downloadUrl}
                  >
                    ดาวน์โหลดไฟล์ Word
                  </a>
                  <a
                    className="btn btn-secondary btn-md"
                    role="button"
                    href={template.downloadUrlPdf}
                  >
                    ดาวน์โหลดไฟล์ Pdf
                  </a>
                </Card.Body>
              </Card>
            </Col>))
            }
          </Row>
        </Container>
      </div>
      {/* <div className="template-nav">
        <Nav variant="pills" defaultActiveKey="/home" className="flex-column">
          <Nav.Link href="/home">สัญญาทั้งหมด</Nav.Link>
          <Nav.Link href="/home2">สัญญาเช่าบ้าน</Nav.Link>
          <Nav.Link eventKey="link-1">สัญญาเช่าคอนโด</Nav.Link>
          <Nav.Link eventKey="link-2">สัญญาเช่าตึกอาคารพาณิชย์</Nav.Link>
          <Nav.Link eventKey="disabled">สัญญาเช่าที่ดิน</Nav.Link>
        </Nav>
      </div> */}
    </div>
  )
}

const Home = () => {
  return (
    <div>
      <Navbar expand="lg" fixed="sticky">
        <Container>
          <Navbar.Brand href="/">Sanyachao</Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Nav className="me-auto">
              <Nav.Link href="/">หน้าแรก</Nav.Link>
              <Nav.Link href="/property">สร้างสัญญาฟรี</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Heading/>
      <TopCardGroup />
      <TemplateList/>
    </div>
  )
}

export default Home
