import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { contractActions } from './store'

const Deposit = (props) => {
  const contract = useSelector(state => state.contract)
  const dispatch = useDispatch()
  return (
    <div>
      <Card>
        <Card.Header>วันทำสัญญา</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
                วันทำสัญญา
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text"
                  value={contract.contractDate}
                  onChange={(value) => dispatch(contractActions.updateField('contractDate', value.target.value))}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
                ผู้เช่าตกลงจ่ายค่าเช่าล่วงหน้าในวันทำสัญญา เป็นเงินจำนวน (บาท)
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text"
                  value={contract.depositAmount}
                  onChange={(value) => dispatch(contractActions.updateField('depositAmount', value.target.value))}
                />
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <div className="d-grid gap-2 button-group">
        <Link
          className="btn btn-danger btn-md"
          role="button"
          to="/preview"
        >
          สร้างสัญญา
        </Link>
        <Link
          className="btn btn-secondary btn-md"
          role="button"
          to="/contract-condition"
        >
          ย้อนกลับ
        </Link>
      </div>
    </div>
  )
}

export default Deposit
