import * as React from 'react'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import {
  Link
} from 'react-router-dom';
import {
  propertyInfoActions
} from './store'
import AddressForm from './AddressForm'

const PropertyInfo = () => {
  const propertyInfo = useSelector(state => state.propertyInfo)
  return (
    <div>
      <Card>
        <Card.Header>ที่อยู่ทรัพย์สิน</Card.Header>
        <Card.Body>
          <AddressForm address={propertyInfo} actions={propertyInfoActions}/>
        </Card.Body>
      </Card>
      <div className="d-grid gap-2 button-group">
        <Link
          className="btn btn-danger btn-md"
          role="button"
          to="/landlord"
        >
          ถัดไป
        </Link>
      </div>
    </div>
  )
}

export default PropertyInfo
