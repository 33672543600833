import * as React from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import ProgressBar from 'react-bootstrap/ProgressBar'
import {
  NavLink,
  Link,
  useLocation
} from 'react-router-dom';
import logoImage from './logo.png';
import useGaTracker from './useGaTracker'


function Sidebar() {
  useGaTracker()
  const location = useLocation()
  const pathName = location.pathname
  const pathList = [
    {path: '/property', title: 'ข้อมูลทรัพย์สินให้เช่า'},
    {path: '/landlord', title: 'ข้อมูลผู้ให้เช่า'},
    {path: '/tenant', title: 'ข้อมูลผู้เช่า'},
    {path: '/contract-condition', title: 'เงื่อนไขในสัญญา'},
    {path: '/deposit', title: 'เงินมัดจำ'}
  ]
  const total = pathList.length
  const currentPosition = pathName === '/preview' ? total : pathList.map(p => p.path).indexOf(pathName) + 1
  const progress = currentPosition / total * 100
  return (
    <div id="sidebar">
      <div id="sidebar-header">
        <Link to="/">
          <img src={logoImage} alt="logo" className="logo-wrapper"/>
        </Link>
        <div id="progress-tab">
          <p className="progress-label">{currentPosition} จาก {total} ขั้นตอน</p>
          <ProgressBar striped variant="danger" now={progress} />
        </div>
      </div>
      <ListGroup variant="flush">
        {pathList.map((pathItem, index) => (
          <ListGroup.Item key={index}>
            <NavLink to={pathItem.path} activeClassName="nav-selected" >
              <div className="link-box">
                {pathItem.title}
              </div>
            </NavLink>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <div id="sidebar-preview">
        <Link
            className="btn btn-danger btn-md"
            role="button"
            to="/preview"
          >
          ดูตัวอย่างสัญญา
        </Link>
        {false && <Link
          className="btn btn-secondary btn-md"
          role="button"
          to="/landlord"
        >
          รู้จักเรา
        </Link>}
      </div>
    </div>
  )
}

export default Sidebar
