import * as React from 'react'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import {
  tenantActions
} from './store'
import AddressForm from './AddressForm'
import PersonalInfoForm from './PersonalInfoForm'

const TenantInfo = () => {
  const person = useSelector(state => state.tenant)
  return (
    <div>
      <Card>
        <Card.Header>ข้อมูลผู้เช่า</Card.Header>
        <Card.Body>
          <PersonalInfoForm person={person} actions={tenantActions}/>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>ที่อยู่ผู้เช่า</Card.Header>
        <Card.Body>
          <AddressForm address={person.address} actions={tenantActions}/>
        </Card.Body>
      </Card>
      <div className="d-grid gap-2 button-group">
        <Link
          className="btn btn-danger btn-md"
          role="button"
          to="/contract-condition"
        >
          ถัดไป
        </Link>
        <Link
          className="btn btn-secondary btn-md"
          role="button"
          to="/landlord"
        >
          ย้อนกลับ
        </Link>
      </div>
    </div>
  )
}

export default TenantInfo
