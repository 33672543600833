import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import './App.css';

import Sidebar from './Sidebar'
import PropertyInfo from './PropertyInfo'
import TenantInfo from './TenantInfo'
import LandlordInfo from './LandlordInfo'
import ContractCondition from './ContractCondition'
import Deposit from './Deposit'
import Preview from './Preview'
import Home from './Home'

const RandomChild = (props) => {
  return (
    <FormLayout>
      <div id="content-wrapper">
        <h2>สัญญาเช่าคอนโด</h2>
        <div>
          <h6>{props.titleName}</h6>
          <div className="content-wrapper">
            {props.children}
          </div>
        </div>
      </div>
    </FormLayout>
  )
}

const FormLayout = (props) => {
  return (
    <div id="page-wrapper">
      <Container fluid>
        <Row>
          <Col xs={2} id="sidebar-wrapper">
            <Sidebar/>
          </Col>
          <Col>
            {props.children}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const routes = [
  {
    path: '/property',
    child: () => <RandomChild titleName="ข้อมูลทรัพย์สินให้เช่า"><PropertyInfo/></RandomChild>
  },
  {
    path: '/landlord',
    child: () => <RandomChild titleName="ข้อมูลผู้ให้เช่า"><LandlordInfo/></RandomChild>
  },
  {
    path: '/tenant',
    child: () => <RandomChild titleName="ข้อมูลผู้เช่า"><TenantInfo/></RandomChild>
  },
  {
    path: '/contract-condition',
    child: () => <RandomChild titleName="เงื่อนไขในสัญญา"><ContractCondition/></RandomChild>
  },
  {
    path: '/deposit',
    child: () => <RandomChild titleName="เงินมัดจำ"><Deposit/></RandomChild>
  },
  {
    path: '/preview',
    child: () => <FormLayout><Preview/></FormLayout>
  },
  {
    path: '/',
    child: () => <Home/>
  }
]

function App() {
  return (
    <Router>
      <Switch>
        {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              children={<route.child/>}
            />
        ))}
      </Switch>
    </Router>
  )
}

export default App;
